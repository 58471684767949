body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.blue_fill {
    fill: rgb(66, 133, 244) !important;
}

.light_blue_fill {
    fill: rgba(66, 134, 244, 0.5) !important;
}

.orange_fill {
    fill: rgb(244, 180, 0) !important;
}

.light_orange_fill {
    fill: rgba(244, 180, 0, 0.5) !important;
}

.red_fill {
    fill: rgb(219, 68, 55) !important;
}

.light_red_fill {
    fill: rgba(219, 68, 55, 0.5) !important;
}

#main-content:has(#categoryList) {
    overflow: visible !important;
}

main:has(#categoryList) {
    width: max-content;
}

.google-visualization-tooltip {
    padding: 0 6px !important;
    width: 200px;
}

.google-visualization-tooltip:has(#google-chart-tooltip-dark) {
    background: #3d3d3d !important;
}

#google-chart-tooltip-dark svg:first-of-type text {
    fill: white !important;
}

.dark-theme-chart text {
    fill: white !important;
}

.dark-theme-chart path{
    stroke: #302f2f !important;
}
